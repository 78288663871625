
import { defineComponent, onBeforeMount, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "step-3",
  components: {
    Field,
    ErrorMessage,
  },
  data() {
    return {
      divisionLabels: {}
    }
  },
  setup() {

    let divisionLabels = ref([]);

    const videoDuration1 = [
          {
            label: "0-30",
            time: 'seconds',
            value: '0-30 Seconds',
            id: "0-30-s",
          },
          {
            label: "30-60",
            time: 'seconds',
            value: '30-60 Seconds',
            id: "30-60-s",
          },
          {
            label: "60-90",
            time: 'seconds',
            value: '60-90 Seconds',
            id: "60-90-s",
          }
      ];
    const videoDuration2 = [
        {
          label: "90-180",
          time: 'seconds',
          value: '90-180 Seconds',
          id: "90-180-s",
        },
        {
          label: "180-360",
          time: 'seconds',
          value: '180-360 Seconds',
          id: "180-360-s",
        },
        {
          label: "6+",
          time: 'minutes',
          value: 'More than 6 Minutes',
          id: "6-plus-m",
        },
      ];
    
    const divisions = [
        {
          label: "Corporate Services",
          value: "Corporate Services",
          id:"corporate-services",
        },
        {
          label: "CST - Customer Strategy and Technology",
          value: "CST - Customer Strategy and Technology",
          id: "Customer-Strategy-and-Technology",
        },
        {
          label: "Greater Sydney",
          value: "Greater Sydney",
          id: "Greater-Sydney",
        },
        {
          label: "Sydney Trains",
          value: "Sydney Trains",
          id: "sydney-trains",
        },
        {
          label: "Maritime",
          value: "Maritime",
          id: "Maritime",
        },
        {
          label: "Infrastructure and Place",
          value: "Infrastructure and Place",
          id: "Infrastructure-and-Place",
        },
        {
          label: "Office of the Secretary (OSec)",
          value: "Office of the Secretary (OSec)",
          id: "Office-of-the-Secretary",
        },
        {
          label: "People and Culture",
          value: "People and Culture",
          id: "People-and-Culture",
        },
        {
          label: "Point to Point Transport Commission",
          value: "Point to Point Transport Commission",
          id: "Point-to-Point-Transport-Commission",
        },
        {
          label: "Regional and Outer Metropolitan (ROM)",
          value: "Regional and Outer Metropolitan (ROM)",
          id: "Regional-and-Outer-Metropolitan",
        },
        {
          label: "Safety Environment and Regulation (SER)",
          value:"Safety Environment and Regulation (SER)",
          id: "Safety-Environment-and-Regulation",
        },
        {
          label: "Sydney Metro",
          value: "Sydney Metro",
          id: "Sydney-Metro",
        },
      ]


    return {
      videoDuration1,
      videoDuration2,
      divisions,
    }
  },

  methods: {
    async getDivisionLabels() {
      ApiService.setHeader();
      const resp = await ApiService.get("monday/division-labels")
        .then(({ data }) => {
          this.divisionLabels = data.data;
        });
    }
  },

  mounted() {
    this.getDivisionLabels();
  }
});
