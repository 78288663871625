
import { ref, defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { enAU } from 'date-fns/locale';

export default defineComponent({
  name: "step-wbs-code",
  components: {
    Field,
    ErrorMessage,
    Datepicker
  },
/*  data() {
      return {
          publishingDate: null,
      };
  },*/
  setup() {
    const publishDate = ref(); //new String); //ref(new Date());
    const projectPublishingDate = ref();

    const dateFormat = (date) => {
      if (date === null) {
        return '';
      }else{
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' }); //date.getMonth() + 1;
        const year = date.getFullYear();

        //return `${month}/${day}/${year}`;
        return `${day} ${month} ${year}`;
      }
    }
    const dateFormatPreview = (date) => {
      if (date === null) {
        return '';
      }else{
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' }); //date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
      }
    }
    const dateTextInputOptions = ref({
      format: 'dd/MM/yyyy'
    })
    const setDate = (value) => {

      if (value === null) {
        publishDate.value = '';

      }else{
        const day = value.getDate();
        const month = value.getMonth() + 1;
        const year = value.getFullYear();

        publishDate.value =`${month}/${day}/${year}`;
        projectPublishingDate.value = `${year}-${month}-${day}`;
      }

    }
    return {
        publishDate,
        projectPublishingDate,
        dateFormat,
        dateFormatPreview,
        dateTextInputOptions,
        setDate,
        enAU
    }
  },

});
