
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "step-1",
  components: {
    Field,
    ErrorMessage,
  },

  setup() {

    const projectTypes = [
          {
            label: "Edit Only",
            value: 'Edit Only',
            id: "edit-only",
          },
          {
            label: "Filming Only",
            value: 'Filming Only',
            id: "filming-only",
          },
          {
            label: "Editing & Filming",
            value: 'Editing & Filming',
            id: "editing-and-filming",
          }
      ];

    return {
      projectTypes,
    }
  },

});
