
import { ref, defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
  name: "step-wbs-code",
  components: {
    Field,
    ErrorMessage,
  },
  setup() {

    return {
    }
  },

});
