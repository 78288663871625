
import { defineComponent, onBeforeMount, ref, reactive } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "step-requester",
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    currentUser: Object,
  },

  watch: {
    currentUser: {
      handler: async function (val) {
        const usr = await store.dispatch(Actions.USER_PROFILE, { user_id: val.id });
        this.userProfile.name = usr.data.name + ' ' + usr.data.last_name;
        this.userProfile.email = usr.data.email;
        this.userProfile.phone = usr.data.phone;
      },
      deep: true
    },
  },

  data() {
    return {
      userProfile: {
        name: '',
        email: '',
        phone: ''
      },
      onBehalfOf: {
        name: '',
        email: '',
        phone: ''
      },
    };
  },
  setup( props ) {
    return {
    };
  },

});
