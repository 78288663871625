
import { computed, defineComponent, onMounted, ref,reactive, onBeforeMount } from "vue";
import { StepperComponent } from "@/assets/ts/components";
import { useForm } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import StepCostCentre from "@/components/projects/new/steps/StepCostCentre.vue";
import StepRequester from "@/components/projects/new/steps/StepRequester.vue";
import Step2 from "@/components/projects/new/steps/StepAbout.vue";
import Step3 from "@/components/projects/new/steps/StepAudience.vue";
import Step4 from "@/components/projects/new/steps/StepVideoInfo.vue";
import Step5 from "@/components/projects/new/steps/StepVideoDetails.vue";
import Step6 from "@/components/projects/new/steps/StepPublishingPlatforms.vue";
import Step7 from "@/components/projects/new/steps/StepCompleted.vue";
import {
  setCurrentPageTitle,
  //setCurrentPageBreadcrumbs,
} from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import JwtService from "@/core/services/JwtService";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

const STATUS_INITIAL = 0, STATUS_SAVING = 1;

interface IStepCostCentre {
  projectWBSCode: string;
}

interface IStepRequester {
  requestorName: string;
  requestorPhone: string;
  requestorEmail: string;
  onBehalfOfName: string;
  onBehalfOfEmail: string;
  onBehalfOfPhone: string;
}

interface IStep2 {
  projectName: string;
  projectType: string;
}

interface IStep3 {
  audienceType: string;
}

interface IStep4 {
  videoDuration: string;
  videoName: string;
  division: string;
}

interface IStep5 {
  requestorName: string;
  requestorPhone: string;
  requestorEmail: string;
  projectBrief: string;
  overarchingMessage: string;
  callToAction: string;
  projectUse: string;
  musicStyle: string;
  tags: string;
}

interface IStep6 {
  //playbookSelection: string;
  projectPublishingDate: string;
  projectPlatform: string;
}

interface CreateAccount extends IStepCostCentre, IStepRequester, IStep2, IStep3, IStep4, IStep5, IStep6 {}

export default defineComponent({
  name: "kt-horizontal-wizard",

  props: {
    currentUser: Object,
  },

  watch: {
    currentUser: function (val) {
      //console.log(val);
    },
  },

  data() {
    return {
      ccurrentUser: {},
    };
  },

  components: {
    StepCostCentre,
    StepRequester,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7
  },

  setup(props, currentStatus) {
    const router = useRouter();
    const _stepperObj = ref<StepperComponent | null>(null);
    const horizontalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const brandShortName = process.env.VUE_APP_BRAND_SHORT_NAME;
    //let currentUser = reactive({});

    const formData = ref<CreateAccount>({
      projectPublishingDate: "",
      projectPlatform: "",
      projectWBSCode: "",
      projectName: "",
      projectType: "",
      audienceType: "Internal",
      videoDuration: "",
      videoName: "",
      division: "",
      requestorName: "",
      requestorPhone: "",
      requestorEmail: "",
      onBehalfOfName: "",
      onBehalfOfEmail: "",
      onBehalfOfPhone: "",
      projectBrief: "",
      overarchingMessage: "",
      callToAction: "",
      projectUse: "",
      musicStyle: "",
      tags: "",
      //playbookSelection: "#1",
    });

    let isSaving = reactive({
      status: false
    });

    let userProfile = {
      name: '', 
      last_name: '',
      email: '', 
      phone: ''
    };

    onBeforeMount( () => {
      //currentUser = store.getters.currentUser.user;
      //console.log(currentUser);
    });

    onMounted( () => {
      _stepperObj.value = StepperComponent.createInsance(
        horizontalWizardRef.value as HTMLElement
      );

      setCurrentPageTitle("Create New Project");
      //setCurrentPageBreadcrumbs("Vertical", [formData.value.projectName]);
    });

    const createProjectSchema = [
      Yup.object({
        projectWBSCode: Yup.string()
          .min(2)
          .max(50)
          .required()
          .label("WBS Code")
      }),
      Yup.object({
        requestorName: Yup.string()
          .min(5)
          .max(100)
          .required()
          .label("Requester Name"),
        requestorPhone: Yup.string()
          .min(5)
          .max(100)
          .required()
          .label("Requester's Phone"),
        requestorEmail: Yup.string().email().required().label("Email"),
      }),
      Yup.object({
        projectName: Yup.string()
          .min(5)
          .max(100)
          .required()
          .label("Project Name"),
        projectType: Yup.string().required().label("Project Type"),
      }),
      Yup.object({
        audienceType: Yup.string().required().label("Account Type"),
      }),
      Yup.object({
        videoDuration: Yup.string().required().label("Video Duration"),
        videoName: Yup.string().label("Video Name"),
        division: Yup.string().required().label("Division"),
      }),
      Yup.object({
        projectBrief: Yup.string().required().label("Project Brief"),
        overarchingMessage: Yup.string()
          .required()
          .label("Overarching Message"),
        callToAction: Yup.string().label("Call To Action"),
        projectUse: Yup.string().label(
          "Project Use: Where & How Will It Be Used?"
        ),
        musicStyle: Yup.string()
          .min(3)
          .max(100)
          .required()
          .label("Music Style"),
        tags: Yup.string().min(10).max(100).required().label("Tags"),
      }),
      Yup.object({
        //playbookSelection: Yup.string().required().label("Account Type"),
        projectPlatform: Yup.string()
          .min(3)
          .max(255)
          .required()
          .label("Platform, reasons and agenda"),
        projectPublishingDate: Yup.string()
          .min(8)
          .max(10)
          .required()
          .label("Publishing date")
      }),

    ];

    const currentSchema = computed(() => {
      return createProjectSchema[currentStepIndex.value];
    });

    const { resetForm, handleSubmit } = useForm<
      IStepCostCentre | IStepRequester | IStep2 | IStep3 | IStep4 | IStep5 | IStep6
    >({
      validationSchema: currentSchema,
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit( async (values) => {

      verifyAuth();
      /* disable upload */

      formData.value = {
        ...formData.value,
        ...values,
      };

      currentStepIndex.value++;
      if (!_stepperObj.value) {
        return;
      }

      if (currentStepIndex.value === 7) {
        await formSubmit(_stepperObj);
      }

      _stepperObj.value.goNext();
      window.scrollTo(0, 0);
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const formSubmit = async (_stepperObj) => {
      isSaving.status = true;
      ApiService.setHeader();
      const resp = await ApiService.post("projects/new-request", {
        data: formData.value,
      })
        .then(({ data }) => {
          isSaving.status = false;
          if (!data.false && data.code == 501) {
            
            Swal.fire({
              text: "Cannot save project, please try again. Or contact admin. error [501]",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            
            currentStepIndex.value--;
            _stepperObj.value.goPrev();
          }else{
            return true;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });

/*      if (resp) {
        currentStepIndex.value++;
        if (!_stepperObj.value) {
          return;
        }
        _stepperObj.value.goNext();
      }
*/      
    };


    const verifyAuth = () => {
      const token = JwtService.getToken();
      if (token == "" || token === null) {
        router.push({ name: "sign-in" });
      }
      store
        .dispatch(Actions.VERIFY_AUTH)
        .then(() => {
         // currentUser = store.getters.currentUser.user;
        })
        .catch(() => {
          router.push({ name: "sign-in" });
        });
    };

    const formProceed = () => {
      router.push({ name: "upload-files" });
    };

    return {
      formData,
      horizontalWizardRef,
      previousStep,
      handleStep,
      formSubmit,
      totalSteps,
      currentStepIndex,
      brandShortName,
      formProceed,
      isSaving,
      //currentUser
    };
  },

  mounted() {
    this.ccurrentUser = store.getters.currentUser.user;
  },

});
